import React from "react"

const PageHeader = ({ children, textColor, className }) => {
  return (
    <h2
      className={`text-4xl font-bold tracking-wide text-${textColor} mb-8 ${className}`}
    >
      {children}
    </h2>
  )
}

export default PageHeader
