import React from "react"
import { graphql } from "gatsby"

import MainLayout from "../components/main-layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"

class TermsPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <MainLayout location={this.props.location} title={siteTitle}>
        <SEO title="Terms" />
        <div className="container mx-auto">
          <div className="h-auto py-12 space-y-4">
            <PageHeader className="">
              Terms &amp; Conditions for Joyful Noise Living
            </PageHeader>

            <h2 className="font-bold" id="terms-of-service">
              TERMS OF SERVICE
            </h2>
            <p>
              The following Terms of Service may be revised at any time by
              JoyfulNoiseLearning.com, Ashley and Joshua Weaver, (“Site
              Owners”), without notice. By using this website, filling out a
              form, or placing an order, you are agreeing to be bound by the
              then current version. This page was last modified on April 25,
              2020. If you have any questions, please contact ashley @
              joyfulnoiselearning.com. By accessing this website, you are
              agreeing to be bound by these website Terms and Conditions of Use,
              all applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you
              do not agree with any of these terms, you are prohibited from
              using or accessing this site.
            </p>
            <h2 className="font-bold" id="free-printables-terms-of-use">
              Free Printables Terms of Use
            </h2>
            <p>
              Free printables from my site are for personal family use only.
              They are not to be shared or distributed or sold in any capacity.
              If you love what you see, send you friends the link to my site
              where they can get their own. By signing up to get a free
              printable on my site, you agree to be added to my newsletter for
              periodic newsletter mailings.
            </p>
            <h2 className="font-bold" id="sharing-reproduction-distribution">
              Sharing, Reproduction &amp; Distribution
            </h2>
            <p>
              Permission is granted to download the materials (except video) on
              Joyful Noise Learning website for personal, non-commercial viewing
              only. This is the grant of a license, not a transfer of title, and
              under this license you may not: modify or copy the materials; use
              the materials for any commercial purpose, or for any public
              display (commercial or non-commercial); attempt to decompile or
              reverse engineer any software contained on JoyfulNoiseLearning.com
              web site; remove any copyright or other proprietary notations from
              the materials; or transfer the materials to another person or
              “mirror” the materials on any other server. This license shall
              automatically terminate if you violate any of these restrictions
              and may be terminated by Joyful Noise Learning Site Owners at any
              time. Upon the termination of this license, you must destroy any
              downloaded materials in your possession whether in electronic or
              printed format.
            </p>
            <h2 className="font-bold" id="site-security">
              Site Security
            </h2>
            <p>
              Joyful Noise Learning uses an SSL certificate (also known as a
              digital certificate) to encrypt information such as credit card
              and debit card transactions to designated third-party payment
              platforms (PayPal and Stripe). You are responsible for all
              transactions processed through these services. Site Owners are not
              liable for any loss or damage from errant or invalid transactions
              processed through the third-party payment platforms.
            </p>
            <h2 className="font-bold" id="feedback">
              Feedback
            </h2>
            <p>
              Please contact us with any feedback. Keep in mind that we are real
              people and not robots, so please do keep the feedback respectful
              and constructive. Communication
            </p>
            <p>
              We promise not to spam you, or sell your information to third
              parties. You may opt out of our email communications by following
              the unsubscribe instructions located at the bottom of email
              communications. You agree that any disclosure, notice, agreement,
              or other communication that we send to you electronically will
              satisfy any legal requirement, including that such communication
              be in writing.
            </p>
            <h2 className="font-bold" id="limited-liability">
              Limited Liability
            </h2>
            <p>
              Joyful Noise Learning (including but not limited to blog posts,
              ebooks, videos, free downloads, support group content and/or small
              group coaching) are the proprietary intellectual property of
              Joshua and Ashley Weaver.
            </p>
            <p>
              To the fullest extent permissible under applicable law, Joshua and
              Ashley Weaver limits liability. In particular we will not be
              liable for any damages that we cause unintentionally and we will
              not be liable to you for any actual, incidental, indirect or
              consequential loss or damage however caused, provided that nothing
              in this Agreement will be interpreted so as to limit or exclude
              any liability which may not be excluded or limited by law. For
              example, we will not be liable to you for any of the following
              types of damages, whether in contract, tort (including negligence
              and strict liability) or otherwise (whether such loss or damage
              was foreseeable, known or otherwise): (i) loss of revenue; (ii)
              loss of actual or anticipated profits; (iii) loss of the use of
              money; (iv) loss of anticipated savings; or (v) loss or corruption
              of, or damage to, data, systems or programs. Because some
              states/jurisdictions do not allow exclusions as broad as those
              stated above or limitations of liability for consequential or
              incidental damages, the above limitations may, in whole or in
              part, not apply to you. If you are dissatisfied with any portion
              of the Site or the Services, or with any clause of these terms, as
              your sole and exclusive remedy you may discontinue using the Site
              and the Services. Although we will not be liable for your losses
              caused by any unauthorized use of your account, you may be liable
              to others as well as to us if your account is used in violation of
              the terms and conditions of this Agreement.
            </p>
            <p>
              You agree to defend, indemnify and hold harmless Joshua and Ashley
              Weaver, JoyfulNoiseLearning.com, its affiliates, officers,
              directors, employees and agents from and against any and all
              claims, damages, obligations, losses, liabilities, costs or
              expenses (including but not limited to attorney’s fees) arising
              from: (i) your use of and access to the Sites and Services; (ii)
              your violation of any term of this Agreement; (iii) your violation
              of any third-party right, including without limitation any
              copyright, property, or privacy right; or (iv) any claim that your
              Content Submissions caused damage to a third party. This defense
              and indemnification obligation will survive this Agreement and
              your use of the Sites and Services.
            </p>
            <h2 className="font-bold" id="disputes">
              Disputes
            </h2>
            <p>
              If a dispute arises between you and JoyfulNoiseLearning.com, our
              goal is to provide you a neutral and cost effective way to resolve
              the dispute quickly. You agree to first contact customer service
              via the email address provided below to describe the problem and
              seek a resolution. If that does not resolve the issue, then you
              and JoyfulNoiseLearning.com agree that any dispute or claim
              relating to your use of the Services or the Site will be resolved
              through binding arbitration, rather than in court, except that you
              may assert claims in small claims court if your claims qualify. In
              addition, you and Joshua and Ashley Weaver
              (JoyfulNoiseLearning.com) both agree that either party may bring
              suit in court to enjoin infringement or other misuse of
              intellectual property rights. If for any reason a claim proceeds
              in court rather than in arbitration, you and site owners each
              waive any right to a jury trial.
            </p>
            <p>
              You and site owners agree that each may bring claims against the
              other only in your or its individual capacity, and not as a
              plaintiff or class member in any purported class, consolidated, or
              representative action. Further, unless both you and Site Owners
              agree otherwise, the arbitrator may not consolidate more than one
              person’s claims, and may not otherwise preside over any form of a
              representative or class proceeding. Notwithstanding the foregoing,
              this arbitration agreement does not preclude you from bringing
              issues to the attention of federal, state, or local agencies. Such
              agencies can, if the law allows, seek relief against us on your
              behalf. This entire arbitration provision will survive termination
              of this Agreement.
            </p>
            <p>
              In the event that any term of this Agreement is held to be invalid
              or unenforceable by a court of competent jurisdiction, the
              remainder of these terms will remain valid and enforceable. We can
              replace any term that is not valid and enforceable with a term of
              similar meaning, which is valid and enforceable. Any failure by us
              to enforce any aspect of the terms of this Agreement will not
              affect our right to require performance at any subsequent time,
              nor will the waiver by us of any breach by you of any provisions
              of these terms be taken to be a waiver of the provision or
              provisions itself.
            </p>
            <p>Last updated: April 25, 2020</p>
          </div>
        </div>
      </MainLayout>
    )
  }
}

export default TermsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
